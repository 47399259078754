
  /* body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    flex-direction: row;
    background-color: #DEF2F1;  
  }
   */
   .App {
    height: 100vh;
    width:auto;
    overflow-x: hidden;
    overflow-y: visible;
    background-image: linear-gradient(to bottom right, #CAF1DE,#CAE9F5); 
   }
  .container {
    margin-top: 11em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row; 
  }
  .containerCustom {
    margin-top: 16em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row; 
  }
  .containerCustom2{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction:column;
    margin-top: 13em;
  }
  
  *:before,
  *:after {
    z-index: 1;
  }
  
  .button {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 50em;
    padding: 1em 2em;
    margin: 2em;
    border: 0;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    height: 5em;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  }
  .button__text {
    z-index: 2;
    position: relative;
    display: block;
    transition: transform 1s;
  }

  
  .button--green {
    color: #fff;
    background-image: linear-gradient(-135deg, #9De0ad 0%, #34ADA8 52%, #547980 100%);
  }
  
  .button--red {
    color: #fff;
    background-image: linear-gradient(-135deg, #C06C84 0%, #F67280 52%, #F8B195 100%);
  }

  .button--blue {
    color: #fff;
    background-image: linear-gradient(-135deg, #00b4d8 0%, #0077b6 52%, #03045e 100%);
  }

  .button--orange {
    color: #fff;
    background-image: linear-gradient(-135deg, #c54f00 0%, #d83f00 52%, #f1aa00 100%);
  }

  .button--swipe:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.44);
    box-sizing: border-box;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
  }
  .button--swipe:hover .button__text {
    transform: scale(1.09);
  }
  .button--swipe:hover:before, .button--swipe:hover:after {
    transform: translate(0);
  }

  .google-btn {
    width: 184px;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .google-btn .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff;
  }
  .google-icon {
    margin-right: 15px;
    width: 1.5em;
    height: 1.5em;
    margin-bottom: 0.12em; 
  }
  .google-btn .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  .google-btn:hover {
    box-shadow: 0 0 6px #4285f4;
  }
  .google-btn:active {
    background: #1669F2;
  }
  #imgOfWebsiteLink {
    width:60%;
    height:60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .OrganizationInterfaceCodeDisplay {
    margin: auto;
    margin-top: 10em;
    width: 60%;
    text-align: center;
  }
  #spacer-2 {
    min-height: 2em;
  }
  #spacer-1 {
    min-height: 0.5em;
  }
  .centerForNow {
    display: flex;
    justify-content: center;
  }
  .customButtonSmaller {
    height: 2em;
    padding-bottom: 2em;
  }
  .customButtonTextSmaller {
    font-size: 0.9em;
  }
  .pushALittleForward {
    margin-top: -0.7em;
  }

  .closeInText {
    padding-left: 10em;
    padding-right: 10em; 
  }
  @media only screen and (max-width: 900px){
    .OrganizationInterfaceCodeDisplay {
      margin: auto;
      margin-top: 6em;
      width: 60%;
      text-align: center;
    }
    
    #spacer-1 {
      min-height: 1em;
    }
    #spacer-2 {
      min-height: 2em;
    }
  
    .centerForNow {
      display: flex;
      justify-content: center;
    }
  
    #EndMeetingButton {
      margin-bottom: 1em;
    }
    .customButtonSmaller {
      height: 2em;
      padding-bottom: 1em;
    }
    .customButtonTextSmaller {
      font-size: 0.5em;
    }
    .closeInText {
      padding-left: 2em;
      padding-right: 2em; 
    }
  }
  
  .form-style input{
    border:0;
    height:50px;
    border-radius:0;
    border-bottom:1px solid #ebebeb;	
  }
  button{
      height:50px;
  }
  .form-style input:focus{
    border-bottom:1px solid #007bff;	
    box-shadow:none;
    outline:0;
    background-color:#ebebeb;	
  }
  .sideline {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    color:#ccc;
  }

  /*For Really Cool User Image */ 
  #userImage {
    border: 1px solid #a9a7a7;
    border-radius: 100%;
    padding: 4px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .urlImitator {
    color: blue 
  }

  .urlImitator:hover {
    text-decoration: underline;
  }
  
  
  .sideline:before,
  .sideline:after {
      content: '';
      border-top: 1px solid #ebebeb;
      margin: 0 20px 0 0;
      flex: 1 0 20px;
  }
  
  .sideline:after {
      margin: 0 0 0 20px;
  }
  
  #expand {
    height: 30em;
    width: 100em;
  }
  #codeInputer {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.6);
  }

  #backarrow {
    width:2%;
    height:2%;
    margin-top: 2em;
  }

  @media only screen and (max-width: 900px){
    #backarrow {
      width: 10%;
      height: 10%
    }
    #expand {
      height: 10em;
      width: 50em;
      margin-left: -1.75em;
    }
    *, ::after, ::before {
      box-sizing: content-box;
    }
    button {
      box-sizing: border-box;
    }
    input {
      box-sizing: border-box;
    }
    .containerCustom {
      margin-top: 10em;
    }
    .containerCustom2{
      margin-top: 10em;
    }
    .hostingMeetingButtons {
      font-size: 5px;
    }
    #FixForMobile {
      margin-top: 2em;
    }
    .customButtonTextEvenSmaller {
      font-size: 0.30em;
    }
    #imgOfWebsiteLink {
      width:100%;
      height:100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .OptimizedDataForMobile{
      margin: 0em 1.5em
    }
  }
